<template>
  <CContainer class="ticket-details" v-if="ticket">
    <CRow>
      <CCol sm="3" col="4"> Module </CCol>
      <CCol sm="9" col="8">
        {{ ticket.module }}
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="3" col="4"> Issue </CCol>
      <CCol sm="9" col="8">
        <div
          style="background-color: #f1f1f1; padding: 10px; border-radius: 8px"
        >
          <div
            style="background-color: #fff; border-radius: 4px; padding: 10px"
          >
            {{ ticket.issue }}
          </div>
          <div style="padding: 10px 14px">
            {{ ticket.description }}
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="3" col="4"> Attachments </CCol>
      <CCol sm="9" col="8">
        <div class="ticket-details-images" v-viewer="{ movable: false }">
          <img
            class="ticket-image-st"
            v-for="(file, index) in ticket.files"
            :key="index"
            :src="file"
          />
        </div>
        <button
          v-if="ticket.files && ticket.files.length"
          class="ticket-img-view-btn"
          type="button"
          @click="showTicketImage()"
        >
          View
        </button>
        <div v-else>Not Provided!</div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="3" col="4"> Status </CCol>
      <CCol sm="9" col="8">
        <span v-if="ticket.status === 'open'" style="color: green">
          {{ ticket.status }}
        </span>
        <span v-else style="color: red">
          {{ ticket.status }}
        </span>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="3" col="4"> OS Version </CCol>
      <CCol sm="9" col="8">
        {{ ticket.os_version ? ticket.os_version : "Not Provided!" }}
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="3" col="4"> Platform </CCol>
      <CCol sm="9" col="8">
        {{ ticket.platform }}
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="3" col="4"> Reported By </CCol>
      <CCol sm="9" col="8">
        <div class="ticket-raised-by-st">
          <div style="font-weight: 500">
            {{ ticket.user ? ticket.user.username : "Anonymous User" }}
          </div>
          <div>
            {{ ticket.contact_email }}
          </div>
          <div>
            {{ ticket.contact_number }}
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="3" col="4"> Comment </CCol>
      <CCol sm="9" col="8">
        <span v-if="ticket.comment">
          {{ ticket.comment }}
        </span>
        <span v-else> N/A </span>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="3" col="4"> Created At </CCol>
      <CCol sm="9" col="8">
        {{ moment(ticket.createdAt).format("LLL") }}
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="3" col="4"> UpdatedAt </CCol>
      <CCol sm="9" col="8">
        {{ moment(ticket.updatedAt).format("LLL") }}
      </CCol>
    </CRow>
    <div>
      <div class="align-left">
        <CButton
          @click="() => (confirmModal = true)"
          class="mt-4 mb-4"
          color="primary"
          >Update Status</CButton
        >
      </div>
    </div>
    <CModal title="Change ticket status" :show.sync="confirmModal" footer>
      <div v-if="ticket.status === 'open'">
        Are you sure the ticket '{{ ticket.issue }}' created by
        {{ ticket.user ? ticket.user.username : "Anonymous User" }} is
        completed?
        <div style="margin-top: 28px">
          <CInput placeholder="Please enter the comment!" v-model="comment" />
        </div>
      </div>
      <div v-else>
        Are you sure re-open the ticket '{{ ticket.issue }}' created by
        {{ ticket.user ? ticket.user.username : "Anonymous User" }}?
      </div>

      <div slot="footer" class="w-100 align-left">
        <LoaderBtn
          :title="updatingStatus ? 'Confirming...' : 'Confirm'"
          :loading="updatingStatus"
          v-on:clicked="actionUpdateStatus"
        />
      </div>
    </CModal>
  </CContainer>
</template>

<script>
import LoaderBtn from "@/components/LoaderBtn";
import TicketServiceApi from "@/services/api/tickets";
import "viewerjs/dist/viewer.css";
import { directive as viewer } from "v-viewer";
export default {
  data() {
    return {
      id: this.$route.params.id,
      ticket: null,
      confirmModal: false,
      updatingStatus: false,
      comment: null,
    };
  },
  components: {
    LoaderBtn,
  },
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
  mounted() {
    this.getTicketDetails();
  },
  methods: {
    getTicketDetails() {
      TicketServiceApi.getTicketDetails(this.id)
        .then((res) => {
          this.ticket = res.data.ticket;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showTicketImage() {
      const viewer = this.$el.querySelector(".ticket-details-images").$viewer;
      viewer.show();
    },

    actionUpdateStatus() {
      this.updatingStatus = true;
      let status = "open";
      if (this.ticket.status === "open") status = "close";
      TicketServiceApi.updateTickeStatus(this.id, status, this.comment)
        .then((res) => {
          this.ticket.status = res.data.status;
          this.ticket.comment = res.data.comment;
        })
        .finally(() => {
          this.updatingStatus = false;
          this.confirmModal = false;
        });
    },
  },
};
</script>

<style scoped>
.ticket-details {
  background-color: #fff;
  border-radius: 20px;
  padding-top: 38px;
  padding-bottom: 38px;
  padding-left: 38px;
}
.ticket-details .col-4 {
  font-weight: 500;
}
.ticket-details .row {
  margin-bottom: 18px;
}
.ticket-raised-by-st {
  background-color: #f1f1f0;
  padding: 10px 18px;
  border-radius: 8px;
  width: max-content;
}
.ticket-raised-by-st div {
  margin-bottom: 4px;
}
.ticket-image-st {
  height: 42px;
  width: 42px;
  border-radius: 8px;
  margin: 4px;
}
.ticket-img-view-btn {
  background-color: #121d43;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
}
.align-left {
  display: flex;
  justify-content: flex-end;
}
</style>