<template>
  <CButton @click="() => this.$emit('clicked')" color="primary">
    <span v-if="loading">
      <CSpinner color="warning" style="width: 1rem; height: 1rem" grow />
    </span>
    {{ title ? title : "Button" }}</CButton
  >
</template>

<script>
export default {
  props: ["title","loading"],
};
</script>

<style>
</style>